import { Injectable } from '@angular/core';
import { Conf } from './conf';

import { environment } from '../../environments/environment';
import { Pessoa } from '../loopback/models';

@Injectable()
export class SistemaService {
  // admin session
  sessionApiToken: string = null;
  env = environment;

  // avaliador session
  avaliador: Pessoa = null;

  //Dados para exibir
  public Conf = new Conf();

  constructor() {
    // console.log('Serviço de Análises iniciado...');
    if (!this.env.production && this.env['DISABLE_LOGIN']) {
      // console.log('... desabilitando LOGIN [DISABLE_LOGIN = true]!');
      this.sessionApiToken = this.env.API_KEY;
    }
  }

  public get nomeSistema(): string {
    return 'Sistema de Avaliação de Competências';
  }

  /* INICIO - LOGIN & LOGOUT */
  public isLoggedIn() {
    return this.sessionApiToken != null;
  }

  public get username(): string {
    return 'Administrador';
  }

  public login(username: string, password: string) {
    if (
      username === this.env.ADMIN_USERNAME &&
      password === this.env.ADMIN_PASSWORD
    ) {
      this.sessionApiToken = this.env.API_KEY;
      return true;
    } else {
      this.sessionApiToken = null;
      return false;
    }
  }

  public logout() {
    this.sessionApiToken = null;
  }
  /* FIM - LOGIN & LOGOUT */

  /* INICIO - LOG & DEBUG */

  /**
   *
   * @param msg
   */
  public log(msg: string) {
    // console.log(msg);
  }

  public debug(msg: string) {
    if (this.env.debug) {
      this.log(msg);
    }
  }

  /* FIM - LOG & DEBUG */
}
