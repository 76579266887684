import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';

// Routing Module
import { AppRoutingModule } from './app.routing';

// Layouts (Caso mude o layout, ativa o lazy loading para fazer o carregamento separado)
import { PaginaAdminComponent } from './layouts/pagina-admin.component';

//Authguard [Liberar rotas quando usuário está logado]
import { AuthguardGuard } from './guards/authguard.guard';
import { SistemaService } from './sistema/sistema.service';
import { SubgruposService } from './sistema/subgrupos/subgrupos.service';

import { Conf } from './sistema/conf';

// enableProdMode();

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    PaginaAdminComponent
  ],
  providers: [
    Conf,
    AuthguardGuard,
    SistemaService,
    SubgruposService,
    AuthguardGuard
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
