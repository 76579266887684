import { Routes } from '@angular/router';

// Layouts
import { PaginaAdminComponent } from './layouts/pagina-admin.component';

export const routes:Routes = [
  {
    path: '',
    redirectTo: `sistema/index`,
    pathMatch: 'full'
  },
  {
    path: 'index',
    redirectTo: `sistema/index`,
    pathMatch: 'full'
  },
  {
    path: `sistema`,
    redirectTo: 'sistema',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    redirectTo: 'sistema/logout',
    pathMatch: 'full'
  },
  {
    path: ``,
    component: PaginaAdminComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'sistema',
        loadChildren: './sistema/sistema.module#SistemaModule'
      }
    ]
  }
];
