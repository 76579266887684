import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Conf } from '../conf';

@Injectable()
export class SubgruposService {

  public Conf = new Conf();

  constructor(private http: HttpClient) { }

  //EXEMPLO DE CHAMADAS PARA REQ
  add(dados):Observable<any> {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization': localStorage.getItem('tokenUsr')
      })
    };

    let endereco: string = `Endereço aqui`;
    return this.http.post(endereco, dados, httpOptions)
                    .pipe(
                      catchError((e:Response)=> throwError(e))
                    );
  }

}
