export class Conf {
    
/* ========================================================================================= */
/* TRADUÇÃO PARA PORTUGUÊS DAS LISTAGENS */
    LANGUAGE = {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ com um total de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        search: "Pesquisar",
        searchPlaceholder: "Buscar",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
            first: "Primeiro",
            last: "Último",
            next: "Próximo",
            previous: "Anterior",
        },
        aria: {
            sortAscending: ": Ordenar colunas de forma ascendente",
            sortDescending: ": Ordenar colunas de forma descendente",
            paginate: {
                first: "Primeiro",
                last: "Último",
                next: "Próximo",
                previous: "Anterior",
        }
    }
    
}

/* ========================================================================================= */

}