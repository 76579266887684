import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line
  // selector: 'body',
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
}
