import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './pagina-admin.component.html'
})
export class PaginaAdminComponent implements OnInit {

  ngOnInit(): void {}

}
