import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Conf } from '../sistema/conf';
import { SistemaService } from '../sistema/sistema.service';

@Injectable()

export class AuthguardGuard implements CanActivate {

  conf = new Conf();
  group = `/${localStorage.getItem('groupName')}`;

  constructor(
    private sistema: SistemaService,
    private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      
    //Coloque aqui as funções para validação no Middleware
    if (!this.sistema.isLoggedIn()) {
      this.router.navigate(['sistema', 'login']); // index
      return false;
    }

    return true;

  }


}
